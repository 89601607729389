import React from "react";
import { Container, Breadcrumb} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../components/PageWrapper";
import { Section, Title, Post } from "../components/Core";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';

function startabusiness() {
    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Banque à distance VS Banque en ligne, la bataille avec BankBank</title>
                <meta name="description" content="
              Avant la banque en ligne il y'avait la banque à distance des banques traditionnelles. Qu'en est-t-il aujourdhui ? Y'a t-il vraiment une différence ?" />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Contactez-nous</Breadcrumb.Item>
      	 </Breadcrumb>
        	<Title id='mobileTitle'>Contactez-nous</Title>
			<StaticImage
		  	src='../images/contacter-bankbank..png'
			width={1200}
			alt='Contacter bankbank'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<Post>
<div style={{textAlign: 'center'}}>
<h2>Par telephone</h2>
<p>+44 (0)7760 884744</p>
<h2>Par email</h2>
<p>bonjour@bankbank.fr<br></br>
partenaires@bankbank.fr
</p>

<h2>Adresse postale</h2>
<p>
BankBank @ Kohdao Ltd.<br></br>
45B Ecclesbourne Road<br></br>
London, N1 3AF<br></br>
UK
</p>
</div>

     
		</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default startabusiness
